var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c(
        "v-card",
        { staticClass: "main-card" },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: "https://cdn.tsilink.com/images/tsi_link_logo.png" }
          }),
          !_vm.authenticated
            ? _c("authenticate-content", {
                on: {
                  authenticated: function($event) {
                    _vm.authenticated = true
                  }
                }
              })
            : _c("telemetry-content", {
                attrs: { authenticated: _vm.authenticated }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }