import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { i18n } from './i18n'
import VueGtag from 'vue-gtag'
import { getRouter } from './router/index'
import GAWrapper from './plugins/google-analytics'
import axios from 'axios'
import { setupTableau } from './services/setupTableau'

export const vuetifyOptions = {
  customVariables: ['~/assets/vuetify-variables.scss'],
  theme: {
    themes: {
      light: {
        primary: '#00aeef',
        accent: '#FA1717',
        secondary: '#1A1919',
        info: '#00aeef',
        warning: '#F48123',
        error: '#FA1717',
        success: '#4FB74A'
      }
    }
  }
}

setupTableau()

Vue.use(Vuetify)

const setup = async () => {
  axios.defaults.validateStatus = () => true

  const router = await getRouter()

  Vue.config.productionTip = false

  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_ID
      }
    },
    router
  )
  Vue.use(GAWrapper, { id: process.env.VUE_APP_GA_ID })

  new Vue({
    i18n,
    store,
    router,
    vuetify: new Vuetify(vuetifyOptions),
    render: h => h(App)
  }).$mount('#app')
}

setup()
