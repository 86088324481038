import AuthenticateContent from './AuthenticateContent/index.vue'
import TelemetryContent from './TelemetryContent/index.vue'

Vue.component('authenticate-content', AuthenticateContent)
Vue.component('telemetry-content', TelemetryContent)

export default {
  name: 'PublicHomePage',
  data () {
    return {
      authenticated: false
    }
  }
}
