var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c(
        "v-card-text",
        [
          _c("v-text-field", {
            attrs: {
              type: _vm.clientCredentials.type,
              label: _vm.$t("publicHome.clientCredentials.id"),
              maxlength: "255"
            },
            model: {
              value: _vm.clientCredentials.id,
              callback: function($$v) {
                _vm.$set(_vm.clientCredentials, "id", $$v)
              },
              expression: "clientCredentials.id"
            }
          }),
          _c("br"),
          _c("v-text-field", {
            attrs: {
              type: _vm.clientCredentials.type,
              label: _vm.$t("publicHome.clientCredentials.secret"),
              maxlength: "255"
            },
            model: {
              value: _vm.clientCredentials.secret,
              callback: function($$v) {
                _vm.$set(_vm.clientCredentials, "secret", $$v)
              },
              expression: "clientCredentials.secret"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "primary authButton",
          attrs: { block: "", disabled: _vm.disableAuthenticateButton },
          on: {
            click: function($event) {
              return _vm.authenticate()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("publicHome.clientCredentials.authenticate")))]
      ),
      _vm.oAuthErrorMessage !== null
        ? _c("div", { staticClass: "error-text" }, [
            _vm._v(_vm._s(_vm.oAuthErrorMessage))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }