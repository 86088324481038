export default {
  name: 'TelemetryContent',
  data () {
    return {
      activeTab: 'range-tab',
      dateRangeOptions: [
        {
          age: 1,
          label: this.$t('publicHome.dateRange.lastDay')
        },
        {
          age: 7,
          label: this.$t('publicHome.dateRange.lastWeek')
        },
        {
          age: 14,
          label: this.$t('publicHome.dateRange.lastTwoWeeks')
        },
        {
          age: 30,
          label: this.$t('publicHome.dateRange.lastMonth')
        }
      ],
      selectedDateRange: null,
      calendarDateRange: [],
      devices: [],
      selectedDevices: [],
      parameters: [
        {
          id: 'model',
          alias: 'Model',
          dataType: window.tableau.dataTypeEnum.string
        },
        {
          id: 'serial',
          alias: 'Serial',
          dataType: window.tableau.dataTypeEnum.string
        },
        {
          id: 'is_public',
          alias: 'Is Public',
          dataType: window.tableau.dataTypeEnum.bool
        },
        {
          id: 'is_indoor',
          alias: 'Is Indoor',
          dataType: window.tableau.dataTypeEnum.bool
        },
        {
          id: 'location',
          alias: 'Location',
          dataType: window.tableau.dataTypeEnum.geometry
        },
        {
          id: 'mcpm1x0',
          alias: 'PM 1.0',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'mcpm2x5',
          alias: 'PM 2.5',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'mcpm2x5_aqi',
          alias: 'PM 2.5 AQI',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'mcpm4x0',
          alias: 'PM 4.0',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'mcpm10',
          alias: 'PM 10',
          dataType: window.tableau.dataTypeEnum.int
        }, {
          id: 'mcpm10_aqi',
          alias: 'PM 10 AQI',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ncpm0x5',
          alias: 'PM 0.5 Num. Conc.',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ncpm1x0',
          alias: 'PM 1.0 Num. Conc.',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ncpm2x5',
          alias: 'PM 2.5 Num. Conc.',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ncpm4x0',
          alias: 'PM 4.0 Num. Conc.',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ncpm10',
          alias: 'PM 10 Num. Conc.',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'tpsize',
          alias: 'Typical Particle Size',
          dataType: window.tableau.dataTypeEnum.float
        }, {
          id: 'temperature',
          alias: 'Temperature (C)',
          dataType: window.tableau.dataTypeEnum.float
        },
        {
          id: 'rh',
          alias: 'Relative Humidity',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'co2_ppm',
          alias: 'CO2',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'ch2o_ppb',
          alias: 'CH2O',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'baro_inhg',
          alias: 'Pressure',
          dataType: window.tableau.dataTypeEnum.float
        },
        {
          id: 'co_ppm',
          alias: 'CO',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'so2_ppb',
          alias: 'SO2',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'o3_ppb',
          alias: 'O3',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'no2_ppb',
          alias: 'NO2',
          dataType: window.tableau.dataTypeEnum.int
        },
        {
          id: 'voc_mgm3',
          alias: 'TVOC',
          dataType: window.tableau.dataTypeEnum.int
        }
      ],
      selectedParameters: []
    }
  },
  props: {
    authenticated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    minDate () {
      let minDate = new Date(Date.now())
      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())
      minDate.setDate(minDate.getDate() - 30)

      return minDate.toISOString()
    },
    maxDate () {
      let maxDate = new Date(Date.now())
      maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate())

      return maxDate.toISOString()
    },
    requestDataButtonDisabled () {
      return this.selectedDevices.length === 0 ||
             this.selectedParameters.length === 0 ||
             (this.activeTab === 'calendar-tab' && this.calendarDateRange.length < 2) ||
             (this.activeTab === 'range-tab' && this.selectedDateRange === null)
    }
  },
  methods: {
    clearDateRange () {
      this.calendarDateRange = []
    },
    async getDevices () {
      await this.$store.dispatch('getDevices', window.tableau)
      this.devices = this.$store.getters.devices
    },
    async submitTableau () {
      const connectionData = {
        devices: this.selectedDevices,
        parameters: this.selectedParameters,
        dateRange: null,
        age: null
      }

      if (this.activeTab === 'calendar-tab') {
        connectionData.dateRange = this.calendarDateRange
      } else {
        connectionData.age = this.selectedDateRange
      }
      window.tableau.connectionData = JSON.stringify(connectionData)
      window.tableau.submit()
    },
    selectAllParameters () {
      this.$nextTick(() => {
        if (this.selectedParameters.length === this.parameters.length) {
          this.selectedParameters = []
        } else {
          this.selectedParameters = this.parameters.slice()
        }
      })
    }
  },
  watch: {
    authenticated: {
      immediate: true,
      handler (newValue) {
        if (newValue) {
          this.getDevices()
        }
      }
    },
    calendarDateRange: {
      handler (newRange) {
        const startDate = new Date(newRange[0])
        const endDate = new Date(newRange[1])

        if (startDate > endDate) {
          this.calendarDateRange.reverse()
        }
      }
    }
  },
  mounted () {
    const tableau = window.tableau
    if (tableau.connectionData) {
      const connectionData = JSON.parse(tableau.connectionData)
      this.selectedDevices = connectionData.devices
      this.selectedParameters = connectionData.parameters
      if (connectionData.dateRange) {
        this.activeTab = 'calendar-tab'
        this.calendarDateRange = connectionData.dateRange
      } else {
        this.selectedDateRange = connectionData.age
      }
    }
  }
}
