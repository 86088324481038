var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c(
        "v-card-text",
        [
          _c("v-select", {
            attrs: {
              items: _vm.devices,
              "item-text": "metadata.friendlyName",
              "item-value": "device_id",
              multiple: "",
              label: _vm.$t("publicHome.telemetryContent.deviceSelection")
            },
            model: {
              value: _vm.selectedDevices,
              callback: function($$v) {
                _vm.selectedDevices = $$v
              },
              expression: "selectedDevices"
            }
          }),
          _c("br"),
          _c("v-select", {
            attrs: {
              items: _vm.parameters,
              multiple: "",
              label: _vm.$t("publicHome.telemetryContent.parameterSelection"),
              "item-text": "alias",
              "item-value": "id",
              "return-object": ""
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-item",
                fn: function() {
                  return [
                    _c(
                      "v-list-item",
                      {
                        attrs: { ripple: "" },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                          },
                          click: _vm.selectAllParameters
                        }
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color:
                                    _vm.selectedParameters.length ===
                                    _vm.parameters.length
                                      ? "primary"
                                      : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedParameters.length ===
                                      _vm.parameters.length
                                      ? "mdi-checkbox-marked"
                                      : "mdi-checkbox-blank-outline"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Select All")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "mt-2" })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.selectedParameters,
              callback: function($$v) {
                _vm.selectedParameters = $$v
              },
              expression: "selectedParameters"
            }
          }),
          _c("br"),
          _c(
            "v-tabs",
            {
              attrs: { centered: "" },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  attrs: { href: "#range-tab" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "range-tab"
                    }
                  }
                },
                [
                  _c("div", { staticClass: "tabs-title" }, [
                    _vm._v("Select Range")
                  ])
                ]
              ),
              _c("div", { staticClass: "tabs-divider" }, [_vm._v("or")]),
              _c(
                "v-tab",
                {
                  attrs: { href: "#calendar-tab" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "calendar-tab"
                    }
                  }
                },
                [
                  _c("div", { staticClass: "tabs-title" }, [
                    _vm._v("Use Calendar")
                  ])
                ]
              ),
              _c(
                "v-tab-item",
                { staticClass: "tabs-content", attrs: { value: "range-tab" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.dateRangeOptions,
                      "item-text": "label",
                      "item-value": "age",
                      label: "Select Time Range"
                    },
                    model: {
                      value: _vm.selectedDateRange,
                      callback: function($$v) {
                        _vm.selectedDateRange = $$v
                      },
                      expression: "selectedDateRange"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  staticClass: "tabs-content",
                  attrs: { value: "calendar-tab" }
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    attrs: {
                                      label: "Calendar Date Selection",
                                      placeholder: "YYYY/MM/DD",
                                      "prepend-icon": "mdi-calendar",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.calendarDateRange,
                                      callback: function($$v) {
                                        _vm.calendarDateRange = $$v
                                      },
                                      expression: "calendarDateRange"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            "no-title": "",
                            range: "",
                            min: _vm.minDate,
                            max: _vm.maxDate,
                            "show-current": false
                          },
                          model: {
                            value: _vm.calendarDateRange,
                            callback: function($$v) {
                              _vm.calendarDateRange = $$v
                            },
                            expression: "calendarDateRange"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary clearDateRangeButton",
                              attrs: { block: "" },
                              on: { click: _vm.clearDateRange }
                            },
                            [_vm._v("Clear")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "primary authButton",
          attrs: { disabled: _vm.requestDataButtonDisabled, block: "" },
          on: {
            click: function($event) {
              return _vm.submitTableau()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("publicHome.telemetryContent.requestData")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }