import axios from 'axios'

export function setupTableau () {
  const tableau = window.tableau

  const tableauWDC = tableau.makeConnector()

  tableauWDC.init = function (initCallback) {
    tableau.authType = tableau.authTypeEnum.custom
    initCallback()
  }

  tableauWDC.getSchema = function (schemaCallback) {
    const connectionData = JSON.parse(tableau.connectionData)

    let telemetryCols = [{
      id: 'cloud_device_id',
      alias: 'Device ID',
      dataType: tableau.dataTypeEnum.string
    }, {
      id: 'timestamp',
      alias: 'Timestamp (UTC)',
      dataType: tableau.dataTypeEnum.datetime
    }]

    telemetryCols = telemetryCols.concat(connectionData.parameters)

    const telemetryTableSchema = {
      id: 'telemetry',
      alias: 'Telemetry',
      columns: telemetryCols
    }

    schemaCallback([telemetryTableSchema])
  }

  tableauWDC.getData = async function (table, doneCallback) {
    if (table.tableInfo.id === 'telemetry') {
      const connectionData = JSON.parse(tableau.connectionData)

      for (const device of connectionData.devices) {
        const requestString = tableauWDC.buildRequestString(device, connectionData.dateRange, connectionData.age, connectionData.parameters)

        const passwordObject = JSON.parse(tableau.password)
        const config = {
          headers: {
            Authorization: passwordObject.token,
            Accept: 'application/json',
            'X-TSI-User-Agent': 'TableauWDC'
          }
        }
        const resp = await axios.get(requestString, config)
        const telemetryEntries = resp.data

        if (telemetryEntries.length > 0) {
          for (const telemetry of telemetryEntries) {
            delete telemetry.cloud_account_id
            if (telemetry.longitude && telemetry.latitude) {
              const longitude = telemetry.longitude
              const latitude = telemetry.latitude
              const location = {
                type: 'Point',
                coordinates: [
                  longitude,
                  latitude,
                  0
                ]
              }
              delete telemetry.longitude
              delete telemetry.latitude
              telemetry.location = location
            }
          }
          table.appendRows(telemetryEntries)
        }
      }
    }
    doneCallback()
  }

  tableauWDC.buildRequestString = function (device, dateRange, age, parameters) {
    let requestString = `${process.env.VUE_APP_APIGEE_HOST}${process.env.VUE_APP_APIGEE_ENV}/api/v3/external/telemetry/flat-format?device_id=${device}`

    if (age) {
      requestString += `&age=${age}`
    } else {
      const startDate = new Date(dateRange[0] + ' 00:00:00.000')
      const endDate = new Date(dateRange[1] + ' 23:59:59.999')
      requestString += `&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
    }

    for (const parameter of parameters) {
      requestString += `&telem[]=${parameter.id}`
    }
    return requestString
  }

  tableau.registerConnector(tableauWDC)
}
