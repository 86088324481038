export default {
  name: 'AuthenticateContent',
  data () {
    return {
      clientCredentials: {
        type: 'text',
        id: '',
        secret: ''
      }
    }
  },
  methods: {
    hasValidOAuthToken () {
      let token, expiration
      if (window.tableau.password) {
        try {
          const passwordObject = JSON.parse(window.tableau.password)
          token = passwordObject.token
          expiration = passwordObject.expiration
        } catch (err) {
          return false
        }
      }
      return token &&
             token.length &&
             token.substr(0, 6) === 'Bearer' &&
             new Date(expiration) > new Date()
    },
    async authenticate () {
      const clientCredentials = JSON.parse(JSON.stringify(this.clientCredentials))
      await this.$store.dispatch('getOAuthToken', { clientCredentials, tableau: window.tableau })
      this.clientCredentials.id = ''
      this.clientCredentials.secret = ''

      if (this.oAuthErrorMessage === null) {
        this.$emit('authenticated')
      }
    }
  },
  computed: {
    oAuthErrorMessage () {
      return this.$store.getters.oAuthErrorMessage
    },
    disableAuthenticateButton () {
      return this.clientCredentials.id === '' || this.clientCredentials.secret === ''
    }
  },
  mounted () {
    if (this.hasValidOAuthToken()) {
      this.$emit('authenticated')
    }
  }
}
