import { isOkResponse } from '@/services/utils'
import { i18n } from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    oAuthErrorMessage: null,
    devices: null,
    telemetry: null
  },
  actions: {
    async getOAuthToken ({ commit, state }, { clientCredentials, tableau }) {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          grant_type: 'client_credentials'
        }
      }
      const urlencoded = new URLSearchParams()
      urlencoded.append('client_id', clientCredentials.id)
      urlencoded.append('client_secret', clientCredentials.secret)
      const response = await axios.post(`${process.env.VUE_APP_APIGEE_HOST}/oauth/client_credential/accesstoken`,
        urlencoded,
        config
      )
      if (isOkResponse(response.status)) {
        const passwordObject = {}
        passwordObject.token = 'Bearer ' + response.data.access_token
        passwordObject.expiration = new Date(Date.now() + (response.data.expires_in * 1000))

        tableau.password = JSON.stringify(passwordObject)

        commit('resetOAuthErrorMessage')
      } else {
        switch (response.status) {
          case 403:
            state.oAuthErrorMessage = i18n.t('publicHome.oAuthErrors.403')
            break
          case 429:
            state.oAuthErrorMessage = i18n.t('publicHome.oAuthErrors.429')
            break
          default:
            state.oAuthErrorMessage = i18n.t('publicHome.oAuthErrors.default')
        }
      }
    },
    async getDevices ({ commit }, tableau) {
      const passwordObject = JSON.parse(tableau.password)
      const config = {
        headers: {
          Authorization: passwordObject.token,
          Accept: 'application/json',
          'X-TSI-User-Agent': 'TableauWDC'
        }
      }
      const response = await axios.get(`${process.env.VUE_APP_APIGEE_HOST}${process.env.VUE_APP_APIGEE_ENV}/api/v3/external/devices`,
        config
      )
      if (isOkResponse(response.status)) {
        commit('updateDevices', response.data)
      } else {
        // TODO: Implement error handling
      }
    }
  },
  mutations: {
    updateDevices (state, devices) {
      state.devices = devices
    },
    updateTelemetry (state, telemetry) {
      state.telemetry = telemetry
    },
    resetOAuthErrorMessage (state) {
      state.oAuthErrorMessage = null
    }
  },
  getters: {
    devices (state) {
      return state.devices
    },
    telemetry (state) {
      return state.telemetry
    },
    oAuthErrorMessage (state) {
      return state.oAuthErrorMessage
    }
  }
})
